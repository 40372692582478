<template>
  <div>
    <div class="mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="flex pb-4">
      <div class="w-full">
        <form
          class="pb-4"
          @keydown="removeError($event.target.id)"
          @submit.prevent="update(pensum)">
          <h3 class="my-4 text-2xl">Actualizar pensum</h3>

          <p class="mb-4 font-bold text-gray-800">Información básica</p>

          <div class="w-full">
              <a-input
                required
                label="Nombre"
                class="mb-4"
                id="name"
                :error="error('name')"
                v-model="pensum.name"/>

              <a-input
                label="Descrípción"
                required
                class="mb-4"
                id="description"
                :error="error('description')"
                v-model="pensum.description"/>

              <a-select
                label="Especialidad"
                required
                class="mb-4"
                @input="removeError('specialization_id')"
                :loading="loadingSpecializations"
                :error="error('specialization_id')"
                :source="specializations.map(el => ({ label: el.title, value: el.id }))"
                v-model="pensum.specialization_id"/>

              <template>
                <label for="specialization_id" class="block font-medium text-gray-700 mb-2">¿Habilitar para uso?</label>
                <a-switch v-model="pensum.finished" class="mb-4" />
              </template>
            </div>

          <div class="mt-2 flex space-x-2 justify-end">
            <a-button class="mr-2" type="submit" :loading="loading">
              Actualizar información básica
            </a-button>

            <a-button outlined :to="{ name: 'pensums.index' }">
              Cancelar
            </a-button>
          </div>
        </form>

        <hr>

        <h1 class="my-4 text-2xl text-gray-800">Semestres {{ pensum.semesters.length ? `(${pensum.semesters.length})` : '' }}</h1>

        <div class="space-y-2">
          <semester-row
            v-for="(semester, index) in pensum.semesters"
            :semester="semester"
            @remove="removeSemester"
            @store="handleStoreSemester($event, index)"
            :index="index"
            is-updating
            @update="updateSemester"
            :courses="filteredCourses"
            v-model="pensum.semesters[index]"
            :key="index" #default="{ current }">
            {{ courses.find(course => course.id === current)
            ? courses.find(course => course.id === current).title
            : `${loadingCourses ? 'cargando' : 'Materia no encontrada'}`
            }}
          </semester-row>
        </div>

        <div class="flex flex-wrap justify-between space-y-2 items-end">
          <div>
            <a-button outlined @click="addSemester" :loading="loadingSemesters">Agregar semestre</a-button>
          </div>

          <div class="flex space-x-2">
            <a-button :loading="loading" @click="update(pensum)">
              Guardar
            </a-button>

            <a-button outlined :to="{ name: 'pensums.index' }">
              Cancelar
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import SemesterRow from './components/semester-row'
export default {
  components: {
    SemesterRow
  },
  data: () => ({
    pensum: {
      name: '',
      description: '',
      specialization_id: null,
      semesters: [],
      finished: false
    },
    courseFilter: ''
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      pensums: state => state.pensums.pagination.data,
      currentPensumOnState: state => state.pensums.pensum,
      specializations: state => state.specializations.all,
      loadingSpecializations: state => state.specializations.loading,
      loadingCourses: state => state.courses.loading,
      courses: state => state.courses.all,
      loading: state => state.pensums.loading,
      loadingSemesters: state => state.semesters.loading
    }),
    filteredCourses() {
      return this.courses.filter($0 => {
        const matchLowerCase = $0.title.toLowerCase().includes(this.courseFilter.toLowerCase())
        const isNotSelected = !this.pensum.semesters.some($1 => $1.courses_ids.some($2 => $2 === $0.id))
        return matchLowerCase && isNotSelected
      })
    }
  },
  methods: {
    ...mapActions({
      fetchPensum: 'pensums/show',
      update: 'pensums/update',
      removeError: 'removeError',
      fetchSpecializations: 'specializations/fetchSpecializations',
      fetchCourses: 'courses/index',
      updateSemester: 'pensums/updateSemester',
      deleteSemester: 'pensums/deleteSemester',
      storeSemester: 'semesters/store',
    }),
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    addSemester() {
      let numbers = this.pensum.semesters.map(it => it.number).sort()
      let current = Math.max(...numbers)
      if (current < 0) {
        current = 0
      }

      let [min, max] = [Math.min(...numbers), Math.max(...numbers)]
      if (min >= 1 && max > 1) {
        let out = Array.from(Array(max - min), (v, i) => i + min).filter(i => !numbers.includes(i))
        if (out.length > 0) {
          current = out[0] - 1
        }
      }

      this.pensum.semesters.push({
        number: current + 1,
        courses_ids: []
      })
    },

    handleStoreSemester(semester, index) {

      this.storeSemester({
        semester: {
          ...semester,
          pensum_id: this.pensum.id,
        },
        completion: (id, semester) => {
          this.pensum.semesters.splice(index, 1, {
            ...semester,
            courses_ids: []
          })
        }
      })
    },
    removeSemester(index) {
      this.pensum.semesters.splice(index, 1)
    },
    removeCourseId(semester_index, course_index) {
      this.pensum.semesters[semester_index].courses_ids.splice(course_index, 1)
    },
    dropSemester(semester) {
      this.deleteSemester({
        ...semester,
        completion: () => {
          let index = this.pensum.semesters.findIndex(el => el.id === semester.id)
          this.pensum.semesters.splice(index, 1)
        }
      })
    }
  },
  watch: {
    currentPensumOnState(val) {
      this.pensum = {
        ...val,
        semesters: val.semesters.map(semester => ({
          ...semester,
          courses_ids: semester.enabled_courses.map(el => el.id)
        })).sort((a,b) => a.number - b.number),
        finished: val.version !== null
      }
    },
    specializations(val) {
      let found = val.find(specialization => specialization.id === this.pensum.specialization_id)
      if (found) {
        this.pensum.specialization_id = found.id
      }
    }
  },
  created() {
    const id = this.$route.params.id

    this.fetchSpecializations({ limit: 0 })
    this.fetchCourses({ limit: 0, enabled: true })
    this.fetchPensum({
      id, query: {
        with: `semesters.enabledCourses`
      }
    })
  }
}
</script>
